// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/api/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"127ab1a11dda8c7df9950f68544c6ba0a42b64a2"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

if (process.env.NEXT_PUBLIC_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2087aa7a84562c46473a01e050db02bb@o4508054139961344.ingest.de.sentry.io/4508093931716688',

    // Add optional integrations for additional features
    integrations: [Sentry.replayIntegration()],

    // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
    tracesSampleRate: 1,

    // Define how likely Replay events are sampled.
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // Define how likely Replay events are sampled when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    // Use the monitoring endpoint for source maps and error reporting
    tunnel: '/api/monitoring',

    // Enable source maps
    attachStacktrace: true,

    // Ensure proper CORS handling
    allowUrls: ['https://test.tidycalls.com', 'https://tidycalls.com'],
  })
}
